:root {
    --colorBackground: 255, 255, 255;
    --colorHeaderText: 0, 174, 239;
    --colorLink: 0, 122, 167;
    --colorText: 0, 0, 0;
    --colorHeader: 159, 207, 103;
    --colorWarning: 229, 25, 55;
    --colorH2: 95, 96, 98;
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Merriweather";
    src: url("./assets/Merriweather-Regular.ttf");
}

@font-face {
    font-family: "MerriweatherBold";
    src: url("./assets/Merriweather-Bold.ttf");
}

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

html, body {margin: 0; height: 100%; }
body {
    padding: 64px 8px;
    background: rgb(var(--colorBackground));
    color: rgb(var(--colorText));
    font-family: Merriweather, serif;
    font-weight: 400;
}
article {margin: 0 auto; max-width: 960px; }
article p, article input, article button, article select {font-size: 22px; }
article textarea {margin-top: 0.5em; font-size: 18px; }
article select {max-width: 100%; }

article #tabs section p {
    margin: 0.5em 0;
    clear: both;
    overflow: auto;
}
article #tabs section p:nth-child(even) {
    margin: 0 -12px;
    padding: 0.5em 12px;
    background: rgba(0, 0, 0, 0.03);
}

header {
    padding: 0 8px;
    background: rgb(var(--colorBackground));
    position: fixed;
    top: 0; left: 0; right: 0;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}
header > div {
    margin: 0 auto;
    max-width: 960px;
}
header div > p {
    margin: 0;
    line-height: 64px;
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
}
header div > p a {
    padding: 0 0 0 160px;
    display: inline-block;
    text-decoration: none;
    background: transparent url(./assets/HELM.png) no-repeat;
    background-position: center left 0;
    background-size: 120px 27px;
    font-family: Poppins, sans-serif;
    color: rgb(var(--colorHeaderText));
}
header div > p:last-child {
    float: right;
    text-align: right;
}
header div > p:last-child a {
    padding: 0;
    background: transparent none;
}

main {
    padding-bottom: 64px;
}

main h1 {
    margin: 12px 0; padding: 0 0 0 8px;
    font-size: 32px;
    font-weight: normal;
    color: rgb(var(--colorHeader));
    line-height: 48px;
    font-family: Poppins, sans-serif;
    text-transform: uppercase;
}
main h2 {
    margin: 0.2em 0;
    font-size: 28px;
    font-weight: normal;
    font-family: MerriweatherBold;
    color: rgb(var(--colorH2));
}
main h3, h4, h5, h6 {
    font-weight: normal;
    font-family: MerriweatherBold;
}
main h3 {
    font-size: 24px;
}
main h4 {
    font-size: 22px;
}
main h5 {
    font-size: 18px;
}
main h1 span, main h2 span, main h3 span, main h4 span, main h5 span {
    font-weight: normal;
}
main h1 + p {
    margin: -4px 0 24px; padding: 0 0 0 8px;
}
main a {
    color: rgb(var(--colorLink));
    font-family: MerriweatherBold;
}
main a:hover {
    color: rgb(var(--colorLink), 0.7);
}

section {
    margin: 0 8px 8px; padding: 12px;
    border-radius: 12px;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.2);
}
section *:first-child {
    margin-top: 0;
}

input:required, select:required, textarea:required {
    outline: 1px solid #f78f1e;
}

input:required:valid, select:required:valid, textarea:required:valid {
    outline: 1px solid #9fcf67;
}
input[type=checkbox], input[type=radio] {
    transform: scale(1.5);
}
input[type=radio] {
    margin-left: 1em;
}
label:first-child input[type=radio]:first-child {
    margin-left: 0.2em;
}
p > input[type=checkbox]:first-child {
    margin-left: 0.2em;
}

button {
    padding: 8px;
    background-color: rgb(var(--colorHeaderText));
    color: rgb(var(--colorBackground));
    border-radius: 12px;
    border: 0 none;
    font-size: 16px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4)
}
button:active {
    position: relative;
    left: 1px; top: 1px;
    box-shadow: 0 0 0;
}
button:disabled {
    background-color: #aaa;
    position: relative;
    left: 1px; top: 1px;
    box-shadow: 0 0 0;
}

textarea {
    width: 100%;
    height: 108px;
}

code {font-size: larger; }

#login label {
    display: inline-block;
    width: 6em;
}
p.warning {color: rgb(var(--colorWarning)); }

/* Form tabs */
nav.tabs {
    margin: 0; padding: 4px 16px 0;
    overflow: auto;
    list-style-type: none;
    height: 58px;
    white-space: nowrap;
}
nav.tabs li {
    margin: 0 3px;
    display: inline-block;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
    font-family: MerriweatherBold;
    font-size: 24px;
    width: 60px; height: 44px;
    line-height: 44px;
    vertical-align: bottom;
    background-color: #eee;
    cursor: pointer;
}
nav.tabs li.active {
    height: 54px;
    background-color: #fff;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.2);
    line-height: 40px;
}

nav.tabs li.empty {
    font-family: Merriweather;
    color: #bbb;
    background-color: #e3e3e3;
}

nav.tabs li.empty.active {
    font-family: MerriweatherBold;
    color: #bbb;
    background-color: #fff;
}

#tabs span.yesno {
    white-space: nowrap;
    float: right;
}

#tabs p > input, #tabs p > select, #tabs p > button.now {
    float: right;
}
#tabs p > input[type="checkbox"] {float: none; }
#tabs p > button.now {margin-right: 0.5em; }
#tabs p > input[data-formula] {text-align: right; }

#save-footer {
    position: fixed;
    left: 0; right: 0; bottom: 0;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.2);;
}
#save-footer p {
    margin: 0.5em 0.5em 0;
}
#save-footer p button {
    margin: 0 0.2em 0.5em;
}

#dealerFilter {
    margin-right: 8px;
}

img.spinner {
    position: fixed;
    top: 76px; right: 24px;
    width: 48px; height: 48px;
}

#serviceFlowLogo img {
    float: right;
    width: 210pt;
    clear: both;
}

pre {
    margin-top: -0.1em;
    padding: 0.2em 120pt 0.2em 0.2em;
    box-shadow: inset 0 0 0 2048px #eee; /* hack for background color that prints */
    font-family: Merriweather;
    white-space: pre-wrap;
}

p.sectionDescription {
    white-space: pre-wrap;
}
div.reportForm {
    margin-bottom: 60px;
}
div.reportForm section p > span {
    display: inline-block;
    padding: 0 0.2em;
    border-bottom: 1px solid #000;
    min-width: 40pt;
    text-align: center;
}
div.reportForm section p > span:empty {
    width: 80pt;
    border-bottom: 1px solid #000;
}
div.reportForm p > span.right-align {
    float: right;
    clear: right;
}
#print {float: right; }

/* Mobile */
@media (max-width: 540px) {
    body {
        padding-top: 32px;
        -webkit-print-color-adjust:exact !important;
        print-color-adjust:exact !important;
    }
    header div > p {
        line-height: 32px;
        font-size: 14px;
    }
    header div > p a {
        padding: 0 0 0 40px;
        background: transparent url(./assets/HELM-sm.png) no-repeat;
        background-size: 24px 24px;
        background-position: 4px 4px
    }
    main h1 {
        margin: 18px 0;
        line-height: 24px;
        font-size: 20px;
    }
    main h2 {
        font-size: 20px;
    }
    main h3 {
        font-size: 18px;
    }
    main h5 {
        font-size: 14px;
    }
    article p, article input, article button, article select {font-size: 17px; }
    #tabs label {
        margin-bottom: 4px;
        display: block;
    }
    #tabs span.yesno label, #tabs label.check {
        margin-bottom: 0;
        display: inline;
    }
    #tabs span.yesno {
        float: none;
    }
    #tabs p > input, #tabs p > select, #tabs p > button.now {
        float: none;
    }
    #tabs p > button.now {margin-right: 0; }
    #tabs p > input[data-formula] {text-align: right; }

    nav.tabs {
        height: 44px;
    }
    nav.tabs li {
        font-size: 20px;
        width: 36px; height:32px;
        line-height: 32px;
    }
    nav.tabs li.active {
        height: 40px;
    }
    #dealerFilter {
        margin-right: 0;
    }
    img.spinner {
        top: 48px;
        width: 32px; height: 32px;
    }
}

@media print {
    @page {
        size: auto; 
        margin: 36pt 36pt 36pt 36pt;
    }
    body {
        padding: 0;
    }
    main {margin: 0 auto; width: 540pt; }
    main > header, #reportHeader {
        display: none;
    }
    main .reportForm h5 {display: none; }
    .avoid-break {page-break-inside: avoid; }
    #print {display: none; }
}
